import React from "react";
import Navbar from "../components/skeleton/Navbar";
import HomeHeader from "../components/home/HomeHeader";
import MainProjectsSection from "../components/projects/MainProjectsSection";
import Footer from "../components/skeleton/Footer";
import GoToTop from "../components/utils/GoToTop";

const Home = () => {
    return (
        <div>
            <Navbar/>
            <HomeHeader/>
            <MainProjectsSection/>
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Home;
