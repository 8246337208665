import "./AboutContentStyles.css";


import React from "react";
import {Link} from "react-router-dom";

import AboutImg1 from "../../assets/about/cyber_security_image.jpg";
import AboutImg2 from "../../assets/about/programming_skill_img.png";

import Divider from "../utils/Divider";
import SkillsGrid from "./SkillsGrid";
import CurriculumVitae from "./CurriculumVitae";

import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

const AboutContent = () => {


    return (
        <div>

            <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" delay="200">
                <div className="about">
                    <div className="left">
                        <h1>Who Am I?</h1>
                        <p style={{marginLeft: "1em", marginRight: "1em"}}>
                            Hello, my name is Andrew and I am passionate about technology, with a specific interest in
                            cyber security. I have always been fascinated by the way technology can be used to protect
                            and secure our online lives and I am dedicated to learning all I can in this field.<br></br>
                            I have been studying and researching in the area of cyber security for a while now and I am
                            constantly updating my knowledge and skills to stay current in this ever-evolving
                            field.<br></br>
                            I am excited to be able to apply my knowledge and skills in the real world and to help make
                            the online world a safer place for everyone.
                        </p>
                        <Link to="/contact">
                            <button className="btn">Contact</button>
                        </Link>

                    </div>

          <div className="right">
            <div className="img-container">
              <div className="img-stack top">
                <img src={AboutImg1} className="img" alt="true" />
              </div>
              <div className="img-stack bottom">
                <img src={AboutImg2} className="img" alt="true" />
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <Divider />
      <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <SkillsGrid />
      </AnimationOnScroll>
      {/*<Divider />
      <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <CurriculumVitae />
  </AnimationOnScroll>*/}
    </div>
  );
};

export default AboutContent;
