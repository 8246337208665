import "./FormStyles.css";

import React, {useState} from "react";

import {send} from '@emailjs/browser';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Form = () => {

    const [toSend, setToSend] = useState({
        from_name: '',
        from_email: '',
        subject: '',
        message: '',
    });

    const handleSuccess = () => {
        toast.success('Message sent!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
        setToSend({
            from_name: '',
            from_email: '',
            subject: '',
            message: '',
        });
    }

    const handleError = () => {
        toast.error('An error occurred, retry.', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (toSend.from_name.length === 0 || toSend.from_email.length === 0 || toSend.subject.length === 0 || toSend.message.length === 0) {
            return
        }

        send(
            'service_dim6s4g',
            'template_ebv2ait',
            toSend,
            'aGjZCxeLNWREU1UJK'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                handleSuccess();
            })
            .catch((err) => {
                console.log('FAILED...', err);
                handleError()
            });
    };

    const handleChange = (e) => {
        setToSend({...toSend, [e.target.name]: e.target.value});
    };

    return (
        <div className="form" onSubmit={onSubmit}>
            <form>
                <label>Your Name</label>
                <input
                    name='from_name'
                    type="text"
                    value={toSend.from_name}
                    onChange={handleChange}></input>
                <label>Email</label>
                <input
                    name='from_email'
                    type="email"
                    value={toSend.from_email}
                    onChange={handleChange}></input>
                <label>Subject</label>
                <input
                    name='subject'
                    type="text"
                    value={toSend.subject}
                    onChange={handleChange}></input>
                <label>Message</label>
                <textarea
                    name='message'
                    rows="6"
                    placeholder="Type your message here"
                    value={toSend.message}
                    onChange={handleChange}/>
                <button className="btn" type='submit'>Submit</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>

    );
};


export default Form;
