import React from "react";

import './MyFlatmatesTitleSectionStyle.css'


const MyFlatmatesTitleSection = () => {
    return (
        <div>
            <div className="myflatmates-title-maincontainer">
                <h1 className="myflatmates-heading">Info</h1>
                <div className="myflatmates-container">
                    <p align="center">This project was born from a need based on personal experience living with roommates. <br/>
                        MyFlatmates allows you to solve various problems related to the management of debts, notes, shopping lists and chores.<br/>
                        Built with Java and Android Studio.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MyFlatmatesTitleSection;
