import pro1 from "../../assets/projects/myflatmates-card.png";
import pro2 from "../../assets/projects/homeserver-card.jpeg";
import pro3 from "../../assets/projects/api-card.png";

const ProjectCardData = [
    {
        imgsrc: pro1,
        title: "MyFlatmates",
        text: "Mobile application for Android devices with the aim of helping people to manage their debts, notes, shopping lists and household chores between flatmates. Features a simple and intuitive interface.",
        view: "myflatmates",
    },
    {
        imgsrc: pro2,
        title: "Home Server",
        text: "Personal home server built with a Raspberry Pi. It has various features such as film and series streaming, files and torrent downloader, system and services manager, dev environments. Remote supported!",
        view: "home-server",
    },
    {
        imgsrc: pro3,
        title: "Python API Automation",
        text: "With the use of Docker, there are several container each of them containing one API automation built with Python. His task is to manage automations between two services or APIs such as Github, Drive or AWS.",
        view: "",
    },
];

export default ProjectCardData;
