import React from "react";
import {Outlet} from "react-router-dom";

const LayoutContainer = () => {
    return (
        <>
            <Outlet/>
        </>
    );
};

export default LayoutContainer;