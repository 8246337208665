import React from "react";

import comingSoonBG from "../assets/home/coming_soon_bg.jpg";
import {Route, Routes} from "react-router-dom";

import Home from "../routes/Home";
import About from "../routes/About";
import Project from "../routes/Project";
import Contact from "../routes/Contact";
import MyFlatmates from "../routes/projects/MyFlatmates";
import LayoutContainer from "../routes/LayoutContainer";
import HomeServer from "../routes/projects/HomeServer";
import PythonAPI from "../routes/projects/PythonAPI";
import NoPage from "../routes/NoPage";


export default function HomeNavigator({isPublic}) {


    if (isPublic === true) {
        return getDefaultPage()
    } else if (isPublic === false) {
        return getComingSoonPage()
    } else {

        return (<div></div>)
    }
}

function getDefaultPage() {
    return (
        <>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="projects" element={<LayoutContainer/>}>
                    <Route index element={<Project/>}/>
                    <Route path="myflatmates" element={<MyFlatmates/>}/>
                    <Route path="home-server" element={<HomeServer/>}/>
                    <Route path="python-api-automation" element={<PythonAPI/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
                <Route path="about" element={<About/>}/>
                <Route path="contact" element={<Contact/>}/>
                <Route path="*" element={<NoPage/>}/>
            </Routes>
        </>
    );
}

function getComingSoonPage() {
    return (
        <div>
            <img
                src={comingSoonBG}
                alt=""
                style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    height: '100vh'
                }}/>
        </div>
    );
}