import "./ProjectsCardStyles.css";
import ProjectsCard from "./ProjectsCard";
import ProjectCardData from "./ProjectsCardData";

import React from "react";

const MainProjectsSection = () => {
    return (
        <div className="work-container">
            <h1 className="project-heading">Projects</h1>
            <div className="project-container">
                {ProjectCardData.map((val, ind) => {
                    return (
                        <ProjectsCard
                            key={ind}
                            imgsrc={val.imgsrc}
                            title={val.title}
                            text={val.text}
                            view={val.view}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default MainProjectsSection;
