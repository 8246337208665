import React from "react";
import "./index.css";

import HomeNavigator from "./components/HomeNavigator";

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getPerformance} from "firebase/performance";
import {fetchAndActivate, getBoolean, getRemoteConfig} from "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyBJNftv_JbhdSKoTevon0nWMMboSZm5JcM",
    authDomain: "react-web-app-personal.firebaseapp.com",
    projectId: "react-web-app-personal",
    storageBucket: "react-web-app-personal.appspot.com",
    messagingSenderId: "465921645360",
    appId: "1:465921645360:web:16bbad50ae88b54a2a86f5",
    measurementId: "G-DPKQ2EZ8NM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
const perf = getPerformance(app);

remoteConfig.settings = {
    minimumFetchIntervalMillis: 0,
};

function App() {

    const [isPublic, setIsPublic] = React.useState(null);

    fetchAndActivate(remoteConfig)
        .then(() => {

            console.log('Configs were retrieved from the backend and activated.');

            let result = getBoolean(remoteConfig, "is_public_dev");

            console.log(result)

            if (result != null) {
                setIsPublic(result);
            }

        })
        .catch((err) => {
            console.log(
                'No configs were fetched from the backend, and the local configs were already activated: ' + err,
            );
        });


    return (
        <>
            <HomeNavigator isPublic={isPublic}>
            </HomeNavigator>
        </>
    );


}

export default App;
