import React from "react";

import Navbar from "../../components/skeleton/Navbar";
import Footer from "../../components/skeleton/Footer";
import HeaderBG from "../../assets/projects/myflatmates-header.png";
import ProjectsHeader from "../../components/projects/ProjectsHeader";
import MyFlatmatesTitleSection from "../../components/projects/myflatmates/MyFlatmatesTitleSection";
import GoToTop from "../../components/utils/GoToTop";
import MyFlatmatesFeaturesSection from "../../components/projects/myflatmates/MyFlatmatesFeaturesSection";

const MyFlatmates = () => {

    return (
        <div>
            <Navbar/>
            <ProjectsHeader
                heading="MYFLATMATES."
                text=""
                image={HeaderBG}/>
            <MyFlatmatesTitleSection/>
            <MyFlatmatesFeaturesSection/>
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default MyFlatmates;
