import "./CurriculumVitaeStyles.css";


// import curriculumVitaeIt from "../../assets/about/Currivulum_Vitae_Andrea_Lettoli_it.pdf"
// import curriculumVitaeEn from "../../assets/about/Currivulum_Vitae_Andrea_Lettoli_en.pdf"
import DownloadButton from "./DownloadButton";

import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const emoji = require("emoji-dictionary");


export default function CurriculumVitae() {


    console.log(emoji.names);

    return (
        <div>
            <div>
                <a id="cv-anchor" href="#/">
                    <h1 className="center" style={{marginTop: "3em"}}>Curriculum Vitae.</h1>
                </a>
                <p className="center" style={{marginTop: "2em", marginLeft: "1em", marginRight: "1em"}}>
                    Here, you can easily access and download a copy of my Curriculum Vitae for your records.<br></br>
                    Simply click on the link provided and the document will automatically download to your
                    device.<br></br>
                    Please feel free to contact me if you have any further questions.
                </p>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}>

                <div className='row' style={{marginTop: "5em", marginBottom: "5em", display: "flex"}}>
                    <div>
                        <AnimationOnScroll animateIn="animate__jackInTheBox">
                            <DownloadButton
                                buttonText="Download EN"
                                // file={curriculumVitaeEn}
                                fileName="Currivulum_Vitae_Andrea_Lettoli_en"
                            />
                        </AnimationOnScroll>
                    </div>
                    <div style={{marginLeft: "2em"}}>
                        <AnimationOnScroll animateIn="animate__jackInTheBox">
                            <DownloadButton
                                buttonText="Download IT"
                                // file={curriculumVitaeIt}
                                fileName="Currivulum_Vitae_Andrea_Lettoli_it"/>
                        </AnimationOnScroll>

                    </div>

                </div>
            </div>


        </div>
    );
}

//                <Link to={curriculumVitaeIt} target="_blank" download className="btn">{italianText}</Link>
