import React from "react";

import Navbar from "../components/skeleton/Navbar";
import Footer from "../components/skeleton/Footer";
import AboutHeader from "../components/about/AboutHeader";
import AboutContent from "../components/about/AboutContent";

import HeaderBG from "../assets/about/nigth-city.gif"
import GoToTop from "../components/utils/GoToTop";

const About = () => {
    return (
        <div>
            <Navbar/>
            <AboutHeader
                heading="ABOUT."
                text="Im a friendly IT Engineer and Cyber Security lover."
                image={HeaderBG}/>
            <AboutContent/>
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default About;
