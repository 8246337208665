import React from "react";

import './MyFlatmatesFeaturesSectionStyle.css'


const MyFlatmatesTitleSection = () => {
    return (
        <div>
            <div className="myflatmates-features-maincontainer">
                <h1 className="myflatmates-heading">Features</h1>
                <div className="myflatmates-container">

                </div>
            </div>
        </div>
    );
};

export default MyFlatmatesTitleSection;
