import "./DividerStyles.css";

import React from "react";


const Divider = () => {


    return (
        <div className="h-divider">
            <div className="shadow"></div>
        </div>
    );
};

export default Divider;