import React from "react";

import Navbar from "../components/skeleton/Navbar";
import Footer from "../components/skeleton/Footer";
// import PricingCard from "../components/other/PricingCard";
import MainProjectsSection from "../components/projects/MainProjectsSection";
import HeaderBG from "../assets/projects/vaporwawe.gif";
import ProjectsHeader from "../components/projects/ProjectsHeader";
import GoToTop from "../components/utils/GoToTop";

const Project = () => {
    return (
        <div>
            <Navbar/>
            <ProjectsHeader
                heading="PROJECTS."
                text="Some of my most recent works."
                image={HeaderBG}/>
            <MainProjectsSection/>
            {/*<PricingCard />*/}
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Project;
