import React from "react";
import Navbar from "../components/skeleton/Navbar";
import Footer from "../components/skeleton/Footer";
import Form from "../components/contact/Form";
import HeaderBG from "../assets/contact/library.gif";
import ContactHeader from "../components/contact/ContactHeader";
import GoToTop from "../components/utils/GoToTop";

const Contact = () => {
    return (
        <div>
            <Navbar/>
            <ContactHeader
                heading="CONTACT."
                text="Let's have a chat."
                image={HeaderBG}/>
            <Form/>
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default Contact;
