import React from "react";

import Navbar from "../../components/skeleton/Navbar";
import Footer from "../../components/skeleton/Footer";
import HeaderBG from "../../assets/projects/api-card.png";
import ProjectsHeader from "../../components/projects/ProjectsHeader";

const MyFlatmates = () => {
    return (
        <div>
            <Navbar/>
            <ProjectsHeader
                heading="Python API Automation."
                text="Life made it simplier."
                image={HeaderBG}/>
            <Footer/>
        </div>
    );
};

export default MyFlatmates;
