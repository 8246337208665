import "./AboutHeaderStyles.css";

import React, {Component} from "react";


class AboutHeader extends Component {

    render() {

        return (
            <div>
                <div className="bcg-img"
                     style={{
                         backgroundImage: `url(${this.props.image})`,
                         backgroundSize: "cover",
                         backgroundPosition: "center"
                     }}>
                    <div className="heading">
                        <h1>{this.props.heading}</h1>
                        <p>{this.props.text}</p>
                        <div className="content">
                            <a href="#skills-anchor" className="btn">
                                Skills
                            </a>
                            <a href="#cv-anchor" className="btn btn-light">
                                CV
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );

    }
}

//            backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${this.props.image})`,


export default AboutHeader;
