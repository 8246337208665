import "./FooterStyles.css";

import React from "react";

import {FaGithub, FaHome, FaLinkedin, FaMailBulk, FaTwitter,} from "react-icons/fa";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="left">
                    <div className="location">
                        <FaHome size={20} style={{color: "#fff", marginRight: "2rem"}}/>
                        <div>
                            <p>Italy</p>
                        </div>
                    </div>
                    <div className="email">
                        <h4>
                            <FaMailBulk
                                size={20}
                                style={{color: "#fff", marginRight: "2rem"}}
                            />
                            buffer@reaper0x1.red
                        </h4>
                    </div>
                </div>
                <div className="right">
                    <h4>About</h4>
                    <p>
                        My links.
                    </p>
                    <div className="social">
                        <a target="_blank"
                           rel="noreferrer"
                           href="https://twitter.com/Reaper0x1">
                            <FaTwitter
                                size={30}
                                style={{color: "#fff", marginRight: "1rem"}}
                            />
                        </a>
                        <a target="_blank"
                           rel="noreferrer"
                           href="https://github.com/Reaper0x1">
                            <FaGithub
                                size={30}
                                style={{color: "#fff", marginRight: "1rem"}}
                            />
                        </a>
                        <a target="_blank"
                           rel="noreferrer"
                           href="https://linkedin.com/in/andrea-lettoli">
                            <FaLinkedin
                                size={30}
                                style={{color: "#fff", marginRight: "1rem"}}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
