import "./SkillsGridStyles.css";

import React from "react";


export default function Skill({
                                  image,
                                  title,
                                  description
                              }) {


    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <p><br></br>{str}</p>);

        return newText;
    }

    return (
        <div align="left">
            <div align="center" style={{margin: "5em"}}>
                <img src={image} className="img" alt="true" width="300" height="300"/>
                <br></br>
                <div align="left">
                    <h1 align="left">{title}</h1>
                    <NewlineText text={description} align="left"/>
                </div>

            </div>
        </div>
    );

}

