import "./ProjectsHeaderStyles.css";

import React, {Component} from "react";


class ProjectsHeader extends Component {

    render() {

        return (
            <div>
                <div className="bcg-img"
                     style={{
                         backgroundImage: `url(${this.props.image})`,
                         backgroundSize: "cover",
                         backgroundPosition: "center"
                     }}>
                    <div className="heading">
                        <h1>{this.props.heading}</h1>
                        <p>{this.props.text}</p>
                    </div>
                </div>
            </div>

        );

    }
}


export default ProjectsHeader;
