import "./NavbarStyles.css";

import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FaBars, FaTimes} from "react-icons/fa";

import {initializeApp} from "firebase/app";

import reaperImg from "../../assets/reaper0x1.png"


const firebaseConfig = {
    apiKey: "AIzaSyBJNftv_JbhdSKoTevon0nWMMboSZm5JcM",
    authDomain: "react-web-app-personal.firebaseapp.com",
    projectId: "react-web-app-personal",
    storageBucket: "react-web-app-personal.appspot.com",
    messagingSenderId: "465921645360",
    appId: "1:465921645360:web:16bbad50ae88b54a2a86f5",
    measurementId: "G-DPKQ2EZ8NM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);


    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);

    return (
        <div className={color ? "header header-bg" : "header"}>
            <Link to="/">
                <div style={{
                    verticalAlign: "middle",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}>
                    <img src={reaperImg} height="50px" width="50px" alt="Logo" ></img>
                    <h1 style={{marginLeft: "10px"}}>Reaper0x1</h1>
                </div>

            </Link>
            <ul className={click ? "nav-menu active" : "nav-menu"} style={{
                verticalAlign: "middle",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/projects">Project</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>


            <div className="hamburger" onClick={handleClick}>
                {click ? (
                    <FaTimes size={20} style={{color: "#fff"}}/>
                ) : (
                    <FaBars size={20} style={{color: "#fff"}}/>
                )}
            </div>
        </div>
    );
};

export default Navbar;
