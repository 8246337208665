import "./SkillsGridStyles.css";

import React from "react";

import SkillHacking from "../../assets/about/skill_hacker.png";
import SkillCoding from "../../assets/about/skill_code.png";
import SkillAndroid from "../../assets/about/skill_android.png";
import SkillDatabase from "../../assets/about/skill_database.png";
import SkillWeb from "../../assets/about/skill_web.png";
import SkillOS from "../../assets/about/skill_system.png";


import {Col, Container, Row} from 'react-grid-system';

import Skill from "./Skill";

import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const SkillsGrid = () => {

    const descHacking = "Basic hacking skills as a pentester.\nRed Team: XSS, SQL Injection, DOS, Linux and Web Application exploitation.\nBlue Team: attack prevention, logging, intrusion detection."
    const descCoding = "Programming languages: C, Java, Python and Groovy.\nScripting langauges: bash."
    const descAndroid = "Android mobile application developement as an hobby! Coding with Java and Android Studio.\nAndroid rooting and modding."
    const descDatabase = "Known databases: MySQL, MariaDB, Parse, Firebase Firestore."
    const descWeb = "HTML, CSS, JavaScript. \nBasic knowledge: Spring, Hibernate, ReactJS, NodeJS, Groovy, ZK."
    const descOS = "Full knowledge of Windows OS and Unix OS: Kali Linux, Parrot, Ubuntu, Debian, Raspbian (for my raspberry server).\nLinux SysAdmin skills: scripting (bash shell), local management of a system, network management, centralized monitoring and configuration (SNMP and LDAP)."


    return (
        <div>
            <a id="skills-anchor" href="#/">
                <h1 className="center" style={{marginTop: "3em"}}>SKILLS.</h1>
            </a>

            <Container fluid>
                <Row>
                    <Col md={4}>
                        <div>
                            <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                                <Skill
                                    image={SkillHacking}
                                    title="HACKING"
                                    description={descHacking}/>
                            </AnimationOnScroll>
                        </div>

                    </Col>
                    <Col md={4}>
                        <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                            <Skill
                                image={SkillCoding}
                                title="CODING"
                                description={descCoding}/>
                        </AnimationOnScroll>

                    </Col>
                    <Col md={4}>
                        <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                            <Skill
                                image={SkillAndroid}
                                title="ANDROID"
                                description={descAndroid}/>
                        </AnimationOnScroll>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                            <Skill
                                image={SkillDatabase}
                                title="DATABASE"
                                description={descDatabase}/>
                        </AnimationOnScroll>
                    </Col>
                    <Col md={4}>
                        <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                            <Skill
                                image={SkillWeb}
                                title="WEB"
                                description={descWeb}/>
                        </AnimationOnScroll>
                    </Col>
                    <Col md={4}>
                        <AnimationOnScroll animateIn="animate__bounceIn" animateOut="animate__fadeOut">
                            <Skill
                                image={SkillOS}
                                title="OS"
                                description={descOS}/>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SkillsGrid;
