import "./HomeHeaderStyles.css";

import React, {useState} from "react";

import BGImg0 from "../../assets/home/home-bg-0.gif";
import BGImg1 from "../../assets/home/home-bg-1.gif";
import BGImg2 from "../../assets/home/home-bg-2.gif";
import BGImg3 from "../../assets/home/home-bg-3.gif";
import BGImg4 from "../../assets/home/home-bg-4.gif";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRefresh} from '@fortawesome/free-solid-svg-icons'


import {Link} from "react-router-dom";

const bgImagesList = [
    BGImg0,
    BGImg1,
    BGImg2,
    BGImg3,
    BGImg4
];


const HomeHeader = () => {

    const [imgIndex, setImgIndex] = useState([Math.floor(Math.random() * bgImagesList.length)]);
    const [isActive, setActive] = useState(true);

    const imgChangeHandler = () => {

        setActive(!isActive);

        let index = [Math.floor(Math.random() * bgImagesList.length)];

        setImgIndex(index);
        setActive(!isActive);
    };

    return (
        <div className="bcg">
            <div className="mask">
                <img className="main-img" src={bgImagesList[imgIndex]} alt="IntroImg"/>
            </div>
            <div className="content">
                <p>HI, I'M ANDREW.</p>
                <h1>IT ENGINEER.</h1>
                <div>
                    <Link to="/projects" className="btn">
                        Projects
                    </Link>
                    <Link to="/contact" className="btn btn-light">
                        Contact
                    </Link>
                </div>
            </div>
            <FontAwesomeIcon
                icon={faRefresh}
                style={{
                    color: "#fff", marginRight: "1rem", marginLeft: "1em", position: "absolute", bottom: "1em",
                }}
                onClick={imgChangeHandler}
                className={isActive ? "rotate" : "rotate down"}
            />
        </div>

    );
};

export default HomeHeader;
